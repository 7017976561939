<template>
	<div class="data-list list-tab-show-page">
		<template v-if="loaded">
			<div class="row-space-tbf header-list">
				<div class="space-left"></div>
				<div class="content">
					<div class="column-filter column-name">
						<div class="text">{{ $t('general.name') }}</div>
					</div>
					<div v-if="$resize && $mq.above(1270) && !$mq.between([1391, 1800]) && data.from == 'user'" class="column-filter column-role-name">
						<div class="text">{{ $t('roles.role') }}</div>
					</div>
					<div v-if="$resize && $mq.above(1030) && !$mq.between([1391, 1470])" class="column-filter column-measurable">
						<div class="text">{{ $t('responsibility.measurable') }}</div>
					</div>
					<div v-if="$resize && $mq.above(800)" class="column-filter column-type-evaluation">
						<div class="text">{{ $t('responsibility.type_evaluation_label') }}</div>
					</div>
					<div v-if="$resize && $mq.above(501)" class="column-filter column-measurement">
						<div class="text">{{ $t('responsibility.frequency') }}</div>
					</div>
					<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
				</div>
				<div class="space-right"></div>
			</div>

			<template v-if="entries.length">
				<draggable v-model="entries" handle=".handle" ghost-class="ghost" @change="updateOrderList">
					<div class="row-space-tbf row-list-item" :key="'procedura' + entry.id" v-for="entry in entries">
						<div class="space-left" v-if="!data.reorderList"><icon-congratulations v-if="$resize && $mq.above(781)"/></div>
						<div class="space-left" v-else><span class="handle">&#8597;</span></div>
						<div class="content">
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<div class="column-name-title column-name link">
								<div class="name" @click="viewResponsibility(entry)">{{ entry.name }}</div>
							</div>
							<div v-if="$resize && $mq.above(1270) && !$mq.between([1391, 1800]) && data.from == 'user'" class="column-simple-text column-role-name">
								<div class="text">{{ entry.role_name }}</div>
							</div>
							<div v-if="$resize && $mq.above(1030) && !$mq.between([1391, 1470])" class="column-simple-text column-measurable">
								<div class="text">{{ entry.frequency ? $t('general.yes') : $t('general.no') }}</div>
							</div>

							<div v-if="$resize && $mq.above(800)" class="column-name-desc column-type-evaluation">
								<div class="name">{{ entry.type ? $t(`responsibility.type_evaluation.${entry.type}`) : '' }}</div>
								<div class="description" v-if="entry.type == 'value'">{{ $t('responsibility.progress_type_text.' + entry.progress_type, { to: parseFloat(entry.target), unit: entry.unity}  ) }}</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-name-desc column-measurement">
								<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{show: 0, hide: 0}" :disabled="entry.frequency == 'daily'">
									<div class="name">{{ entry.frequency ? $t(`responsibility.frequencies.${entry.frequency}`) : '' }}</div>
									<div class="description" v-if="entry.frequency == 'weekly'">{{ entry.measurement.length ? entry.measurement.map((a) => { return $t(`calendar.long_days.${a}`) }).join(", ") : ''}}</div>
									<div class="description" v-if="entry.frequency == 'monthly'">{{ entry.measurement.length ? entry.measurement.join(", ") : '' }}</div>
									<div class="description capitalize" v-if="entry.frequency == 'yearly'">{{ entry.measurement.length ? entry.measurement.map((a) => { return moment(a, 'MM-DD').format('DD.MM') }).join(", ") : '' }}</div>

									<template slot="popover">
										<div class="simple-text" v-if="entry.frequency == 'weekly'">{{ entry.measurement.length ? entry.measurement.map((a) => { return $t(`calendar.long_days.${a}`) }).join(", ") : ''}}</div>
										<div class="simple-text" v-if="entry.frequency == 'monthly'">{{ entry.measurement.length ? entry.measurement.join(", ") : '' }}</div>
										<div class="simple-text capitalize" v-if="entry.frequency == 'yearly'">{{ entry.measurement.length ? entry.measurement.map((a) => { return moment(a, 'MM-DD').format('DD.MM') }).join(", ") : '' }}</div>
									</template>
								</v-popover>
							</div>

							<div class="column-edit-mobile" v-if="$resize && $mq.below(500) && $route.name == 'roles-show'">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+entry.id" :id="'dropdownEdit'+entry.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ entry.id " :aria-labelledby="'dropdownEdit'+entry.id">
										<div class="dropdown-item" @click="showModal('crud_responsibility', {from: 'role_list', crud: 'edit', responsabilityId: entry.id})"><div class="simple-text">{{ $t('general.edit') }}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'responsibility', from: 'role_list', model: entry})"><div class="simple-text">{{ $t('general.delete') }}</div></div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501) && $route.name == 'roles-show'" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+entry.id" :id="'dropdownEdit'+entry.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ entry.id " :aria-labelledby="'dropdownEdit'+entry.id">
									<div class="dropdown-item" @click="showModal('crud_responsibility', {from: 'role_list', crud: 'edit', responsabilityId: entry.id})"><div class="simple-text">{{ $t('general.edit') }}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'responsibility', from: 'role_list', model: entry})"><div class="simple-text">{{ $t('general.delete') }}</div></div>
								</div>
							</div>
						</div>
					</div>
				</draggable>
			</template>
			<template v-else>
				<div class="row-space-tbf row-list-item">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-empty-list">
							<div class="name"><img src="/build/images/no-results-found-inline.svg">{{ $t('empty.title') }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
		</template>
		<loader-items-list v-else/>
	</div>
</template>


<script>
	import IconUser from '../../Icons/User'
	import IconEdit from '../../Icons/EditDots'
	import IconCongratulations from '../../Icons/Congratulations'
	import IconEditMobile from '../../Icons/Edit'
	import LoaderItemsList from '../../PagesLoaders/ResponsibilitiesList'
	import draggable from 'vuedraggable'

	export default {
		components: {
			IconUser,
			IconEdit,
			IconEditMobile,
			IconCongratulations,
			LoaderItemsList,
			draggable
		},
		data() {
			return {
				loaded: false,
				entries: []
			}
		},
		watch:{
			$route (to, from){
				if(this.data.from == 'role') {
					this.getRoleResponsibilities()
				} else {
					this.getUserResponsibilities()
				}
			}
		},
		props: {
			data: Object
		},
		async mounted(){
			if(this.data.from == 'role') {
				await this.getRoleResponsibilities()
			} else if(this.data.from == 'vacant_job') {
				await this.getVacantJobResponsibilities()
			} else {
				await this.getUserResponsibilities()
			}

			this.$root.$on('refreshResponsibilitiesIndex', () => {
				if(this.data.from == 'role') {
					this.getRoleResponsibilities()
				} else if(this.data.from == 'vacant_job') {
					this.getVacantJobResponsibilities()
				} else {
					this.getUserResponsibilities()
				}
			})
		},
		methods: {
			async getRoleResponsibilities(){
				await axios.get(`/${this.data.id}/role-responsibilities`)
				.then(({data}) => {
					this.entries = data.data
				})
				.finally(() => { setTimeout(() => { this.loaded = true }, 0) })
			},
			async getUserResponsibilities(){
				let paramsQuery = {}

				if(this.$route.query.roles) {
					paramsQuery.role_ids = this.$route.query.roles.split(',').map(Number)
				}

				await axios.get(`/${this.data.slug}/user-responsibilities`, { params: paramsQuery })
				.then(({data}) => {
					this.entries = data.data
				})
				.finally(() => { setTimeout(() => { this.loaded = true }, 0) })
			},
			async getVacantJobResponsibilities(){
				await axios.get(`/${this.data.slug}/vacant-job-responsibilities`)
				.then(({data}) => {
					this.entries = data.data
				})
				.finally(() => { setTimeout(() => { this.loaded = true }, 0) })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			updateOrderList(val){
				this.$emit('populateList', this.entries)
			},
			viewResponsibility(entry) {
				this.$root.$emit('open_modal', 'show_responsibility', {
					from: 'list',
					userId: '',
					userSlug: '',
					id: entry.id,
					date: ''
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.list-tab-show-page{
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.text{
							font-weight: 700;
						}
					}
				}
			}
			&.ghost{
				background: #efefef;
			}
			.handle{
				cursor: grab;
			}
		}
		.column-name{
			flex: 1 1 auto;
			&.link{
				cursor: pointer;
			}
		}
		.column-measurement{
			flex: 0 0 130px;
		}
		.column-type-evaluation{
			flex: 0 0 200px;
		}
		.column-measurable{
			flex: 0 0 120px;
		}
		.column-role-name{
			flex: 0 0 130px;
		}
	}
</style>